<template>
  <a-modal
    title="开票信息"
    :visible="visible"
    :maskClosable="false"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <div class="content">
      <div class="content-item">
        <span class="title">公司名称：</span>
        <span class="desc">武汉剧好看网络科技有限公司</span>
      </div>
      <div class="content-item">
        <span class="title">纳税识别号：</span>
        <span class="desc">91420105MABY981213</span>
      </div>
      <div class="content-item">
        <span class="title">地址、电话：</span>
        <span class="desc">武汉市汉阳区紫荆路1号紫荆嘉苑（F地块及扩大用地）3栋1、3层（2）商-411 18001853449</span>
      </div>
      <div class="content-item">
        <span class="title">开户行：</span>
        <span class="desc">上海浦东发展银行股份有限公司武汉光谷支行</span>
      </div>
      <div class="content-item">
        <span class="title">账户：</span>
        <span class="desc">70040078801800002362</span>
      </div>
    </div>
    <template #footer>
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      // 是否展示
      visible: false,
    }
  },
  methods: {
    // 显示
    showModal () {
      this.visible = true
    },
    // 关闭
    handleCancel () {
      // 隐藏
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.content-item {
  line-height: 26px;
  .title {
    font-size: 14px;
    color: #7A7A7A;
  }
  .desc {
    font-weight: 500;
    font-size: 14px;
    color: #5A5A5A;
  }
}
</style>
